<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`${this.$t('order')} ${reservationID}`"
    />
    <h1 class="text-center">
      {{
        $t('account-title-reservation', {
          reservationOrderID: reservationID
        })
      }}
    </h1>
    <b-card v-if="reservation.WarehouseID">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('status') }}</strong>
            <br />
            {{ reservationStatus }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('retrieval-location') }}</strong>
            <br />
            {{ $store.getters['warehouseName'](reservation.WarehouseID) }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('retrieval-date') }}</strong>
            <br />
            {{ format(reservation.DateTimeExpectedStart) }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('return-date') }}</strong>
            <br />
            {{ format(reservation.DateTimeExpectedEnd) }}
          </p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
          <p>
            <strong>{{ $t('payment') }}</strong>
            <br />
            {{ paymentStatus }}
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="reservation">
      <b-col sm="12">
        <b-table
          v-if="reservationItems.length > 0"
          :fields="fields"
          :items="reservationItems"
          class="mt-4"
        >
          <template v-slot:cell(ItemDescription)="data">
            <small
              v-if="data.item.ItemID && data.item.Composition"
              class="pl-3"
              >{{ data.item.ItemDescription }}</small
            >
            <span v-else>{{ data.item.ItemDescription }}</span>
          </template>
          <template v-slot:cell(Price)="data">
            <div v-if="data.item.Price > 0" class="form-group text-right">
              &euro;
              {{
                (
                  Math.round((data.item.TotalIncVAT / data.item.Amount) * 100) /
                  100
                )
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
          <template v-slot:cell(Amount)="data">
            <div
              v-if="data.item.ItemType !== 'Deposit'"
              class="form-group text-center"
            >
              {{ data.item.Amount }}
            </div>
          </template>
          <template v-slot:cell(TotalIncVAT)="data">
            <div v-if="data.item.TotalIncVAT > 0" class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <table v-if="reservation.TotalIncVAT" class="table w-100">
          <tr>
            <td class="text-right">
              <strong>{{ $t('total-amount') }}:</strong>
            </td>
            <td class="text-right" width="140px">
              &euro;
              {{
                reservation.TotalIncVAT.toFixed(2)
                  .toString()
                  .replace('.', ',')
              }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        v-if="reservation.ReservationStatus != 'Canceled'"
        cols="12"
        class="text-right"
      >
        <hr />
        {{ reservation.Status }}
        <a
          v-if="
            reservation.Balance &&
              reservation.ReservationStatus !== 'Cancelled' &&
              reservation.ReservationStatus !== 'Expired'
          "
          :href="`/${$i18n.locale}/betaling/${reservation.ReservationOrderID}`"
          class="btn btn-primary mb-2 ml-3"
          >{{ $t('click-to-complete-payment') }}
          <font-awesome-icon :icon="['fas', 'credit-card']" class="ml-1" fas
        /></a>

        <b-button
          @click="$bvModal.show('prompt')"
          v-if="allowCancelOrder"
          variant="primary"
          class="float-right float-xs-center ml-3"
        >
          {{ $t('cancel-order') }}
          <font-awesome-icon
            v-if="addReservationToCartPending"
            :icon="['fad', 'spinner']"
            class="fa-spin"
            far
          />
        </b-button>
      </b-col>
    </b-row>
    <modal
      :message="promptMessage"
      @confirm="cancelReservation()"
      modalid="prompt"
    />
  </b-container>
</template>

<script>
import {
  getReservationItems,
  getReservationItem,
  getReservationReport,
  cancelReservation,
  addReservationToCart,
  cancelReservationItem
} from '@/services/ReservationService'
import Modal from '@/components/global/modal-prompt.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  components: {
    modal: Modal
  },
  data() {
    return {
      reservationItems: {},
      reservation: {},
      addReservationToCartPending: false,
      sortBy: 'CompositionDescription',
      fields: [
        {
          key: 'CompositionDescription',
          class: 'd-none'
        },
        {
          key: 'ItemID',
          class: 'd-none'
        },
        {
          key: 'ItemDescription',
          label: this.$t('product')
        },
        {
          key: 'Price',
          label: this.$t('price'),
          class: 'text-right'
        },
        {
          key: 'Amount',
          label: this.$t('amount'),
          class: 'text-center'
        },

        {
          key: 'TotalIncVAT',
          label: this.$t('total'),
          class: 'text-right'
        }
      ],
      breadCrumbs: [
        { page: 'index', title: this.$t('general.bikeRental'), path: '/' },
        { page: 'account', title: this.$t('myaccount'), path: '/account' }
      ]
    }
  },
  computed: {
    allowCancelOrder: function() {
      return dayjs(this.reservation.DateTimeExpectedStart).isAfter(
        dayjs().add(14, 'days')
      )
    },
    reservationID: function() {
      return this.$route.params.reservationID
    },
    paymentStatus: function() {
      if (!this.reservation.Balance) return this.$t('paid')
      return this.$t('not-payed')
    },
    promptMessage: function() {
      return this.$t('cancel-reservation-prompt', {
        reservationID: this.reservationID
      })
    },
    reservationStatus: function() {
      let status = this.reservation.ReservationStatus

      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return this.$t('requested')

      if (status === 'Order') return this.$t('reservation-confirmed')

      if (status === 'Pick' || status === 'Picked')
        return this.$t('in-progress')

      if (status === 'Pending') return this.$t('rented')

      if (status === 'Closed') return this.$t('returned')

      if (status === 'Cancelled' || status === 'Expired')
        return this.$t('cancelled')

      if (status === 'Rejected') return this.$t('declined')

      return this.$t('requested')
    }
  },
  mounted: async function() {
    this.reservation = await getReservationItem({
      reservationID: this.reservationID
    })
    this.reservationItems = await getReservationItems({
      reservationID: this.reservationID
    })
    // this.reservationItems = this.reservationItems.filter(
    //   reservationItem => reservationItem.ItemID !== 'Borg'
    // )
  },
  methods: {
    cancelReservationItemPrompt: async function({
      reservationOrderID,
      reservationItemID,
      adjustable,
      ItemDescription
    }) {
      if (adjustable === 1) {
        let cancel = confirm(
          this.$t('cancel-reservation-item-composition-prompt', {
            ItemDescription: ItemDescription
          })
        )
        if (cancel === false) return
      }

      if (!adjustable) {
        let cancel = confirm(
          this.$t('cancel-reservation-item-prompt', {
            ItemDescription: ItemDescription
          })
        )
        if (cancel === false) return
      }

      await cancelReservationItem({ reservationOrderID, reservationItemID })
      this.refreshReservationItems()
      this.reservation = await getReservationItem({
        reservationID: this.reservationID
      })
    },
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    getReport: async function() {
      await getReservationReport({
        reservationOrderID: this.reservation.ReservationOrderID,
        reportID: this.reservation.ReportID,
        attachmentToken: this.reservation.AttachmentToken,
        fileName: `${this.reservation.ReservationOrderID}-${this.reservation.ReportFileName}`
      })
    },
    addReservationToCart: async function() {
      this.addReservationToCartPending = true
      const result = await addReservationToCart({
        reservationID: this.reservationID
      })

      if (result.data === 'OK') {
        this.$router.push({
          name: 'cart',
          query: { addedReservationItems: true }
        })
      }
      this.addReservationToCartPending = false
    },
    refreshReservationItems: async function() {
      this.reservationItems = await getReservationItems({
        reservationID: this.reservationID
      })
    },
    cancelReservation: async function() {
      await cancelReservation({ reservationID: this.reservationID })
      this.$router.push({
        name: 'account',
        query: {
          infoMessage: `${
            this.reservation.ReservationStatus === 'Active'
              ? this.$t('request')
              : this.$t('order')
          } ${this.$t('cancel-reservation-confirmation-notification', {
            reservationID: this.reservationID
          })} `
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-h-100 .card {
  height: 100% !important;
  .card-body {
    padding-bottom: 100px;
  }
  .card-body-bottom {
    position: absolute;
    bottom: 20px;
  }
}
.prices {
  padding-right: 10px;
}
</style>
